<template>
  <div class="login_wrap">
    <div class="header">
      <img
        class="img-bg"
        src="//res.jzyglxt.com/app/wechat/idi/login_bg_new_2.png"
      />
      <div class="logo-wrap">
        <div class="info">欢迎登录全景云平台</div>
      </div>
    </div>

    <div class="bottom_bar">
      <div class="inner">
        <div class="inp_wrap">
          <input
            placeholder="请输入用户账号"
            class="my_inp"
            v-model="username"
          />

          <input
            placeholder="请输入用户密码"
            class="my_inp"
            type="password"
            v-model="password"
          />
        </div>
        <div class="btn_wrap">
          <pano-button
            name="登 录"
            @click="gotoMain()"
            size="medium"
            style="width:68%;margin:0 auto"
          ></pano-button>
        </div>
        <div class="version">v{{ version }}</div>
      </div>
    </div>
  </div>
</template>

<script>
import { login } from '@/api/common/common.js'
import util from '@/utils/util'
import { setToken } from '@/common/js/auth'
export default {
  name: '',
  data() {
    return {
      show: false,
      username: '',
      password: '',
      version: '1.0.1',
    }
  },
  methods: {
    close() {
      this.show = false
    },

    async gotoMain() {
      let res = await login({
        account: this.username,
        password: util.md5(this.password),
      })
      setToken(res)
      this.$router.push('/')
    },
  },
  created() {},
  mounted() {},
}
</script>

<style scoped lang="scss">
.login_wrap {
  box-sizing: border-box;
  width: 100vw;
  height: 100vh;
  background: #ffffff;

  .header {
    position: relative;

    .img-bg {
      width: 100vw;
      height: 250px;
    }
    .logo-wrap {
      position: absolute;
      width: 100vw;
      height: 250px;
      top: 0;
      left: 0;
      z-index: 9;
      color: white;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      .info {
        // width: 100px;
        // height: 100px;
        font-size: 28px;
        letter-spacing: 2px;
      }
    }
  }
  .top_bar {
    height: 250px;
    width: 100%;
    background-size: 100% 100%;
    background-repeat: no-repeat;
    padding-bottom: 10px;
    // border:1px solid red;
    box-sizing: border-box;

    .inner {
      width: 100%;
      height: 100%;
      position: absolute;
      z-index: 99;

      .info {
        color: #ffffff;
        font-size: 28px;
        text-align: center;
        // margin-top: 40upx;
      }
    }
  }

  .bottom_bar {
    // flex: 1;
    overflow: hidden;
    height: calc(100vh - 500upx);
    box-sizing: border-box;
    border-radius: 30upx 30upx 0 0;
    background: #ffffff;
    width: 100%;
    // border: 1px solid red;
    transform: translateY(-22upx);
    padding: 80upx 100upx 50upx;

    .inner {
      width: 100%;
      height: 100%;
      position: relative;

      .inp_wrap {
        width: 100%;
        padding: 30px 16%;
        .my_inp {
          display: inline-block;
          width: 68%;
          height: 40px;
          border-radius: 50px;
          line-height: 40px;
          background-color: #f7f7f7;
          margin-bottom: 30px;
          padding-left: 20px;
        }
      }

      .btn_wrap {
        margin-top: 30px;

        span {
          color: #ffffff;
          font-size: 16px;
          font-weight: bold;
        }
      }

      .version {
        position: fixed;
        bottom: 15px;
        color: #999999;
        font-size: 12px;
        text-align: center;
        width: 100vw;
        left: 0;
      }
    }
  }
}
</style>
