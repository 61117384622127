import { post } from 'common/js/http'

export function uploadLocal(formData) {
  return post('/api/common/fileServer/uploadLocal', formData)
}
export function uploadOss(formData) {
  return post('/api/common/fileServer/uploadOss', formData)
}

export function login(params) {
  return post('/api/app/passport/login', params)
}
